import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
  state() {
    return {
      //username: localStorage.getItem('username'),
      //uid: localStorage.getItem('uid'),
      username: localStorage.getItem("username"),
      uid: localStorage.getItem("uid"),
      fbid: "",
      token: localStorage.getItem("JWT_TOKEN"),
      prevRoute: "",
    };
  },
  mutations: {
    changeUsername(state, value) {
      state.username = value;
      localStorage.setItem("username", value);
    },
    changeUid(state, value) {
      state.uid = value;
      localStorage.setItem("uid", value);
    },
    changeToken(state, value) {
      state.token = value;
      localStorage.setItem("JWT_TOKEN", value);
    },
    changeFbid(state, value) {
      state.fbid = value;
    },
    changePrevRoute(state, value) {
      state.prevRoute = value;
    },
  },
  getters: {
    username(state) {
      return state.username;
    },
    uid(state) {
      return state.uid;
    },
    token(state) {
      return state.token;
    },
    fbid(state) {
      return state.fbid;
    },
    prevRoute(state) {
      return state.prevRoute;
    },
  },
  actions: {
    setUsername(store, value) {
      store.commit("changeUsername", value);
    },
    setUid(store, value) {
      store.commit("changeUid", value);
    },
    setToken(store, value) {
      store.commit("changeToken", value);
    },
    setFbid(store, value) {
      store.commit("changeFbid", value);
    },
    setPrevRoute(store, value) {
      store.commit("changePrevRoute", value);
    },
  },
});

export default store;
