import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
import { ElMessage } from "element-plus";
import i18n from "@/core/plugins/i18n";

let lang = localStorage.getItem("lang");
if (!lang) {
  lang = "hk";
}
let region = localStorage.getItem("region");
if (!region) {
  region = "hk";
}
let sport = localStorage.getItem("sport");
if (!sport) {
  sport = "tennis";
}


const routes: Array<RouteRecordRaw> = [
  {
    //path: "/:lang",
    path: "/:lang/:region/:sport/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "tour",
        name: "tour",
        component: () => import("@/views/pages/tour/tour.vue"),
        children: [
          {
            path: "list/:page",
            name: "tourlist",
            component: () => import("@/views/pages/tour/tourlist.vue"),
          },
          {
            path: "series",
            name: "tourSeries",
            component: () => import("@/views/pages/tour/tourlist.vue"),
          },
          {
            path: "timetable",
            name: "tourTimetable",
            component: () => import("@/views/pages/tour/tourlist.vue"),
          },
          {
            path: "info/:event_id/:tour_id",
            name: "tourInfo",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
          {
            path: "apply/:event_id/:tour_id",
            name: "tourApply",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
          {
            path: "pay/:event_id/:tour_id",
            name: "tourPay",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
          {
            path: "players/:event_id/:tour_id",
            name: "tourPlayers",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
        ],
      },
      {
        path: "draw",
        name: "draw",
        component: () => import("@/views/pages/tour/draw.vue"),
        children: [
          {
            path: ":event_id/:tour_id",
            name: "tourDraw",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
          {
            path: ":event_id/:tour_id/:divi",
            name: "tourDrawInfo",
            component: () => import("@/views/pages/tour/tourframe.vue"),
          },
        ],
      },
      {
        path: "rank",
        name: "rank",
        component: () => import("@/views/pages/other/rank.vue"),
        children: [
          {
            path: ":mode",
            name: "rankmode",
            component: () => import("@/views/pages/other/ranklist.vue"),
          },
          {
            path: ":mode/:page",
            name: "rankmodepage",
            component: () => import("@/views/pages/other/ranklist.vue"),
          },
        ],
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("@/views/pages/other/contact.vue"),
      },
      {
        path: "news",
        name: "news",
        redirect: (to) => ({ name: "newsPage", params: { page: 1 } }),
      },
      {
        path: "news/:page",
        name: "newsPage",
        component: () => import("@/views/pages/other/news.vue"),
      },
      {
        path: "shop",
        name: "shop",
        redirect: (to) => ({ name: "shopPage", params: { page: 1 } }),
      },
      {
        path: "shop/:page",
        name: "shopPage",
        component: () => import("@/views/pages/other/shop.vue"),
      },
      {
        path: "coach",
        name: "coach",
        redirect: (to) => ({ name: "coachPage", params: { page: 1 } }),
      },
      {
        path: "coach/:page",
        name: "coachPage",
        component: () => import("@/views/pages/other/coach.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("@/views/pages/other/about.vue"),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import("@/views/pages/other/privacy.vue"),
      },
      {
        path: "user/:uid",
        name: "userHome",
        component: () => import("@/views/pages/user/user.vue"),
      },
      {
        path: "user/:uid/:type/:page",
        name: "user",
        component: () => import("@/views/pages/user/user.vue"),
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("@/views/pages/setting/setting.vue"),
      },
      {
        path: "member/schedule/:page",
        name: "schedule",
        component: () => import("@/views/pages/member/schedule.vue"),
      },
      {
        path: "member/report/:type/:id",
        name: "report",
        component: () => import("@/views/pages/member/report.vue"),
      },
      {
        path: "mygame",
        name: "mygame",
        component: () => import("@/views/pages/tour/mygame.vue"),
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/pages/auth/login.vue"),
      },
      {
        path: "login/fb",
        name: "loginFB",
        component: () => import("@/views/pages/auth/login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/pages/auth/register.vue"),
      },
      {
        path: "register/fb",
        name: "registerFB",
        component: () => import("@/views/pages/auth/register.vue"),
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("@/views/pages/auth/logout.vue"),
      },
      {
        path: "forgot",
        name: "forgot",
        component: () => import("@/views/pages/auth/forgot.vue"),
      },
      {
        path: "reset/:id/:code",
        name: "reset",
        component: () => import("@/views/pages/auth/reset.vue"),
      },
      {
        path: "setting/:tab",
        name: "setting",
        component: () => import("@/views/pages/setting/setting.vue"),
      },
      {
        path: "control/:tab",
        name: "control",
        component: () => import("@/views/pages/cp/control.vue"),
      },
      {
        path: "admin/:mode/:tab",
        name: "admin",
        component: () => import("@/views/pages/admin/admin.vue"),
      },
      {
        path: "test",
        name: "test",
        component: () => import("@/views/pages/other/test.vue"),
      },
    ],
  },
  {
    path: "/",
    //redirect: "/dashboard",
    redirect: (to) => ({ name: "tourSeries", params: { lang: "hk", region:"hk", sport: "tennis" } }),
    //redirect: (to) => ({ name: "tourSeries", params: { lang: "hk" } }),
    
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  //store.dispatch(Actions.VERIFY_AUTH);
  store.dispatch("setPrevRoute", from);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next();
});

export default router;
