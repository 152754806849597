
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import axios from "axios";
import { ElMessage } from "element-plus";
import store from "./store";
import { func } from "@/core/plugins/globalfunc";
import { ElConfigProvider } from "element-plus";
import zhTw from "element-plus/lib/locale/lang/zh-tw";
import en from "element-plus/lib/locale/lang/en";
import { useRoute } from "vue-router";

declare const window: any;
var facebookConnectPlugin;
export default defineComponent({
  name: "app",
  mixins: [func],
  components: {
    ElConfigProvider,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
  data() {
    return {
      locale: zhTw,
      fbCount: 0
    };
  },
  computed: {
    detectLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    detectLang() {
      if (this.$i18n.locale == "en") {
        this.locale = en;
      } else if (this.$i18n.locale == "hk") {
        this.locale = zhTw;
      }
    },
  },
  created() {
    // Start :: axios interceptors
    // Set header to local storage JWT token
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("JWT_TOKEN");
    // Axios request interceptors
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("JWT_TOKEN");
        if (token) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // http response interceptors
    axios.interceptors.response.use(
      (response) => {
        if (response.data.code === 401) {
          ElMessage.error(this.$t("loginAgain"));
          this.logoutClear();
        } else {
          if (response.data.jwt) {
            store.dispatch("setToken", response.data.jwt);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.jwt; // Refresh JWT token to header
          }
          return response; // return the query return result
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // End :: axios interceptors
  },
  methods: {
    onDeviceReady() {
      window.facebookConnectPlugin.getLoginStatus(false, function onLoginStatus (status) {
        if (status.authResponse) {
          //If facebook is login, check with fb and login
          axios
            .post(
              "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=fblogin&action=fblogin",
              JSON.stringify({ accessToken: status.authResponse.accessToken })
            )
            .then((response) => {
              if (
                response.data != "fail" &&
                response.data &&
                response.data != "register"
              ) {
                store.dispatch("setUsername", response.data.username);
                store.dispatch("setUid", response.data.uid);
                store.dispatch("setToken", response.data.jwt);
                axios.defaults.headers.common["Authorization"] =
                  "Bearer " + response.data.jwt; // Save returned JWT token to header
              }
            });
        } else if (store.state.token) {
          //If there's token, check with backend to auto login
          axios
            .post(
              "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=jwtLogin",
              JSON.stringify({ accessToken: store.state.token })
            )
            .then((response) => {
              if (response.data) {
                store.dispatch("setUsername", response.data.username);
                store.dispatch("setUid", response.data.uid);
                store.dispatch("setToken", response.data.jwt);
                axios.defaults.headers.common["Authorization"] =
                  "Bearer " + response.data.jwt; // Save returned JWT token to header
              }
            });
        }
      });
    },
  },
  mounted() {

    document.addEventListener("deviceready", this.onDeviceReady, false);
    
  }
});



