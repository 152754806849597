import store from "@/store";

declare const window: any;
export const func = {
  methods: {
    logoutClear() {
      store.dispatch("setUsername", "");
      store.dispatch("setUid", "");
      store.dispatch("setToken", "");
      store.dispatch("setFbid", "");
      //window.FB.logout();
      window.facebookConnectPlugin.logout();
    },
  },
};
